<template>
  <div class="container">
    <div
      class="d-flex justify-content-between align-content-center position-absolute px-2 pt-3"
      style="z-index: 1"
    >
      <img src="/img/logopriadi.png" style="width: 35%" alt="" />
      <!--            <label class="switch">-->
      <!--                <input type="checkbox" id="togBtn">-->
      <!--                <div class="slider-switch round">-->
      <!--                    <span class="id">EN</span>-->
      <!--                    <span class="en">ID</span>-->
      <!--                </div>-->
      <!--            </label>-->
    </div>

    <!-- Slider Wrapper -->
    <div class="css-slider-wrapper">
      <input
        type="radio"
        name="slider"
        class="slide-radio1"
        checked
        id="slider_1"
      />
      <input type="radio" name="slider" class="slide-radio2" id="slider_2" />
      <input type="radio" name="slider" class="slide-radio3" id="slider_3" />
      <input type="radio" name="slider" class="slide-radio4" id="slider_4" />

      <!-- Slider #1 -->
      <div class="slider slide-1">
        <img src="images/model-1.png" alt="" />
        <!--<div class="slider-content">
                    <img src="/img/Brain01c.png" class="d-block mx-auto w-75"
                         alt="...">
                    <p class="fs-3 fw-bold text-white text-center">Temukan Potensi Terbaik Sesuai Karakter Dirimu</p>
                    <p class="lead text-center fs-6 text-white">Hanya dengan melalui sidik jari, peserta tes dapat mengetahui secara akurat kepribadian dasar yang dimilikinya. Tanpa tes tertulis atau tes komputer. Tanpa wawancara & tanpa observasi</p>
                </div>-->
      </div>

      <!-- Slider #2 -->
      <div class="slider slide-2">
        <img src="images/model-2.png" alt="" />
        <!--<div class="slider-content">
                    <img src="/img/Brain02c.png" class="d-block mx-auto w-75"
                         alt="...">
                    <p class="fs-3 fw-bold text-white text-center">"Mood Free"</p>
                    <p class="lead text-center fs-6 text-white">Hasil tes relatif konsisten dalam periode waktu yang lama. Perubahan emosi dan pikiran saat diuji, tidak memengaruhi hasil tes.</p>
                </div>-->
      </div>

      <!-- Slider #3 -->
      <div class="slider slide-3">
        <img src="images/model-3.png" alt="" />
        <!--<div class="slider-content">
                    <img src="/img/Brain03c.png" class="d-block mx-auto w-75"
                         alt="...">
                    <p class="fs-3 fw-bold text-white text-center">Rainbow Model Assessment</p>
                    <p class="lead text-center fs-6 text-white">Hasil tes yang mudah dimengerti. Warna dalam laporan mewakili makna dan nilai yang berbeda.</p>
                </div>-->
      </div>

      <div class="slider slide-4">
        <img src="images/model-4.png" alt="" />
      </div>

      <!-- Slider Pagination -->
      <div class="slider-pagination">
        <label for="slider_1" @click="this.index = 1" class="page1"></label>
        <label for="slider_2" @click="this.index = 2" class="page2"></label>
        <label for="slider_3" @click="this.index = 3" class="page3"></label>
        <label for="slider_4" @click="this.index = 4" class="page4"></label>
      </div>
    </div>
  </div>
  <div class="w-100 position-fixed bottom-0 rounded-3">
    <div
      class="text-center bg-white"
      @click="toggleDrawer"
      style="border-top-right-radius: 20px; border-top-left-radius: 20px"
    >
      <i
        class="bi"
        :class="{
          'bi-chevron-compact-down text-secondary': !isDrawerToggled,
          'bi-chevron-compact-up text-secondary': isDrawerToggled,
        }"
        style="font-size: 2em"
      ></i>
    </div>
    <div
      class="drawer-content bg-white pt-1 pb-3"
      :class="{ '': !isDrawerToggled }"
    >
      <div class="d-grid col-10 mx-auto">
        <p class="lead text-center">Lanjutkan sebagai apa?</p>
        <div class="d-flex justify-content-center mx-auto mb-3">
          <button
            class="border border-info border-2 btn col-7 btn-light mx-2"
            style="border-radius: 23px"
            @click="select_role('individual')"
          >
            <i class="bi bi-person-fill fs-1"></i><br />Individual
          </button>
          <button
            class="border border-info border-2 btn col-7 btn-light mx-2"
            style="border-radius: 23px"
            @click="select_role('hr')"
          >
            <i class="bi bi-briefcase-fill fs-1"></i><br />Corporate
          </button>
        </div>
        <p class="text-center" style="font-size: 9pt">
          Dengan memilih salah satu opsi versi tes berarti Anda setuju dengan
          <router-link to="/nda" class="text-decoration-underline fw-bold"
            >Ketentuan Layanan
          </router-link>
          dan
          <a
            href="https://priadi.id/privacy-policy-apps.html"
            target="_blank"
            class="text-decoration-underline fw-bold"
            >Kebijakan Privasi PRIADI
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "Intro",
  data() {
    return {
      isDrawerToggled: false,
      touchDrawX: "",
      touchDrawY: "",
      tx: "",
      currentTx: "",
      currentPos: "",
      index: 1,
      finishThreshold: 100,
      slide_interval: null,
    };
  },
  mounted() {
    this.toggleSlide();
  },
  methods: {
    toggleDrawer() {
      this.isDrawerToggled = !this.isDrawerToggled;
      $(".drawer-content").delay("200").slideToggle();
    },
    select_role(role) {
      this.$store.dispatch("setRole", role);
      if (this.$store.getters.getRole === "hr") this.$router.push("/auth");
      else this.$router.push("/package");
    },
    toggleSlide: function () {
      let slide = this;
      this.slide_interval = window.setInterval(function () {
        if (slide.index > 4) {
          slide.index = 1;
        }
        document.querySelector(`.page${slide.index}`).click();
        slide.index++;
      }, 3000);
    },
  },
  unmounted() {
    clearInterval(this.slide_interval);
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

body {
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}

.css-slider-wrapper {
  display: block;
  background: #fff;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

/* Slider */
.slider {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  z-index: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  -webkit-transition: -webkit-transform 1600ms;
  transition: -webkit-transform 1600ms, transform 1600ms;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* Slides Background Color */
.slide-1 {
  background-image: url("/img/intro-01.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  left: 0;
}

.slide-2 {
  background-image: url("/img/intro-02.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  left: 100%;
}

.slide-3 {
  background-image: url("/img/intro-03.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  left: 200%;
}

.slide-4 {
  background-image: url("/img/intro-04.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  left: 300%;
}

.slider {
  display: flex;
  justify-content: flex-start;
}

.slider-content {
  width: 635px;
  padding-left: 100px;
}

/* Slider Inner Slide Effect */
.slider h2 {
  color: #333333;
  text-transform: capitalize;
  font-size: 60px;
  font-weight: 300;
  line-height: 1.2;
  opacity: 0;
  -webkit-transform: translateX(500px);
  transform: translateX(500px);
  margin-top: 0;
  letter-spacing: 2px;
}

.slider h4 {
  font-size: 22px;
  font-family: Arial, sans-serif;
  color: rgba(51, 51, 51, 0.349);
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2;
  opacity: 0;
  -webkit-transform: translateX(500px);
  transform: translateX(500px);
}

.slider > img {
  position: absolute;
  right: 10%;
  bottom: 0;
  height: 100%;
  opacity: 0;
  -webkit-transform: translateX(500px);
  transform: translateX(500px);
}

.slide-1 > img {
  right: 0;
}

/* Animations */
.slider h2 {
  -webkit-transition: opacity 800ms, -webkit-transform 800ms;
  transition: transform 800ms, opacity 800ms;
  -webkit-transition-delay: 1s; /* Safari */
  transition-delay: 1s;
}

.slider h4 {
  -webkit-transition: opacity 800ms, -webkit-transform 800ms;
  transition: transform 800ms, opacity 800ms;
  -webkit-transition-delay: 1.4s; /* Safari */
  transition-delay: 1.4s;
}

.slider > img {
  -webkit-transition: opacity 800ms, -webkit-transform 800ms;
  transition: transform 800ms, opacity 800ms;
  -webkit-transition-delay: 1.2s; /* Safari */
  transition-delay: 1.2s;
}

/* Slider Pagger */
.slider-pagination {
  position: absolute;
  bottom: 15%;
  width: 100%;
  justify-content: center;
  /*z-index: 1000;*/
  display: flex;
  align-items: center;
}

.slider-pagination label {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 8px;
  background: #fff;
  margin: 0 5px;
  cursor: pointer;
}

/* Slider Pagger Event */
.slide-radio1:checked ~ .slider-pagination .page1,
.slide-radio2:checked ~ .slider-pagination .page2,
.slide-radio3:checked ~ .slider-pagination .page3,
.slide-radio4:checked ~ .slider-pagination .page4 {
  width: 20px;
  height: 7px;
  border: 2px solid #fff;
  border-radius: 7px;
  background: white;
}

/* Slider Slide Effect */
.slide-radio1:checked ~ .slider {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

.slide-radio2:checked ~ .slider {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.slide-radio3:checked ~ .slider {
  -webkit-transform: translateX(-200%);
  transform: translateX(-200%);
}

.slide-radio4:checked ~ .slider {
  -webkit-transform: translateX(-300%);
  transform: translateX(-300%);
}

.slide-radio1:checked ~ .slide-1 h2,
.slide-radio2:checked ~ .slide-2 h2,
.slide-radio3:checked ~ .slide-3 h2,
.slide-radio1:checked ~ .slide-1 h4,
.slide-radio2:checked ~ .slide-2 h4,
.slide-radio3:checked ~ .slide-3 h4,
.slide-radio1:checked ~ .slide-1 > img,
.slide-radio2:checked ~ .slide-2 > img,
.slide-radio3:checked ~ .slide-3 > img,
.slide-radio4:checked ~ .slide-4 > img {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  .slider h2 {
    font-size: 20px;
  }

  .slider h4 {
    font-size: 16px;
  }

  .slider-content {
    padding: 0 2%;
  }

  .navigation {
    padding: 0 2%;
  }

  .navigation-left {
    display: none;
  }

  .number-pagination {
    right: 2%;
  }

  .slider-pagination {
    left: 0;
  }

  .slider > img {
    right: 2%;
  }

  .slide-1 > img {
    right: -110px;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 35px;
  margin-right: 10px;
}

.switch input {
  display: none;
}

.slider-switch {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: lightgrey;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider-switch:before {
  position: absolute;
  content: "";
  height: 35px;
  width: 35px;
  right: 0;
  background: white;
  border: 1px solid grey;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:focus + .slider-switch {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider-switch:before {
  -webkit-transform: translateX(-35px);
  -ms-transform: translateX(-35px);
  transform: translateX(-35px);
}

/*------ ADDED CSS ---------*/
.id {
  color: grey;
  position: absolute;
  transform: translate(-45%, -50%);
  top: 50%;
  right: 50%;
  font-size: 14px;
  font-family: Verdana, sans-serif;
}

.en {
  color: black;
  position: absolute;
  transform: translate(-45%, -50%);
  top: 50%;
  right: 5%;
  font-size: 14px;
  font-family: Verdana, sans-serif;
}

input:checked + .slider-switch .id {
  color: black;
}

input:checked + .slider-switch .en {
  /*display: none;*/
  color: grey;
}

/*--------- END --------*/

/* Rounded sliders */
.slider-switch.round {
  border-radius: 35px;
}

.slider-switch.round:before {
  border-radius: 50%;
}
</style>
