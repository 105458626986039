<template>
  <div
    v-if="this.$store.getters.isLoading"
    class="d-flex justify-content-center align-items-center loader-wrapper"
  >
    <div>
      <img src="/img/preloader_img.png" class="w-50 d-block mx-auto" alt="" />
    </div>
  </div>
  <router-view v-slot="{ Component, route }">
    <transition :name="route.meta.transition || 'fade'">
      <div :key="route.name">
          <Component :is="Component"></Component>
      </div>
    </transition>
  </router-view>
  <!-- <div v-else>
        <div class="d-flex justify-content-center align-items-center vh-100">
            <div class="container text-center">
                <img src="./assets/mobile_user.svg" alt="" class="mx-auto d-block w-50">
                <h4>Sorry, this website can be only opened in mobile devices. (Recommend browser: Google Chrome)</h4>
                <h6>~Sincerely, PRiADI Team</h6>
            </div>
        </div>
    </div> -->
</template>

<script>
import axios from "axios";
import { UAParser } from "ua-parser-js";

export default {
  name: "App",
  data() {
    return {
      isMobileDevice: Boolean,
      refCount: 0,
      // isLoading: false
    };
  },
  created() {
    // this.isMobileDevice = this.isMobile()
    axios.interceptors.request.use(
      (config) => {
        this.setLoading(true);
        return config;
      },
      (error) => {
        this.setLoading(false);
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        this.setLoading(false);
        return response;
      },
      (error) => {
        this.setLoading(false);
        return Promise.reject(error);
      }
    );
  },
  methods: {
    isMobile() {
      var check = false;

      const parser = new UAParser();

      if (parser.getDevice().type == "mobile") {
        check = true;
      }

      return check;
    },
    setLoading(isLoading) {
      if (isLoading) {
        this.refCount++;
        this.$store.dispatch("setLoading", true);
      } else if (this.refCount > 0) {
        this.refCount--;
        this.$store.dispatch("setLoading", this.refCount > 0);
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";

body {
  font-family: "Montserrat", sans-serif;
}

.click-effect {
  cursor: pointer;
  transition: 0.1s all;
}

.click-effect:active {
  transform: scale(0.9);
}

.fade-effect {
  cursor: pointer;
  transition: 0.1s all;
}

.fade-effect:active {
  opacity: 0.3;
}

.disabled-component {
  pointer-events: none;
  cursor: none;
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: #ffffff;
  text-align: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-left-leave-active,
.slide-left-enter-active {
  transition: 1s;
}
.slide-left-enter {
  transform: translate(100%, 0);
}
.slide-left-leave-to {
  transform: translate(-100%, 0);
}

.slide-right-leave-active,
.slide-right-enter-active {
  transition: 1s;
}
.slide-right-enter {
  transform: translate(-100%, 0);
}
.slide-right-leave-to {
  transform: translate(100%, 0);
}

[v-cloak] {
  display: none !important;
}
</style>
